<template>
  <div class="w-full">
    <div style="margin-bottom: 16px">
      <header
        class="
          mb-4
          flex flex-col-reverse
          items-center
          justify-center
          lg:flex-row lg:justify-between
          gap-3
        "
      >
        <div class="w-full md:max-w-lg grid grid-cols-1 lg:grid-cols-1 gap-4">
          <a-button
            type="primary"
            @click.native="inactiveRole"
            shape="round"
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">INACTIVAR ROL</span>
          </a-button>
        </div>
        <div class="w-full sm:max-w-sm">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative">
            <div
              class="
                flex
                absolute
                inset-y-0
                left-0
                items-center
                pl-3
                pointer-events-none
              "
            >
              <svg
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <a-input-search
              placeholder="Buscar un usuario"
              class="rounded-full"
              style="width: 100%"
              @search="onSearch"
              v-model="myTerm"
            />
          </div>
        </div>
      </header>
    </div>
    <div
      class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white"
    >
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
      >
        <span
          slot="actions_name"
          slot-scope="text, record"
          class="text-sm font-medium text-right whitespace-nowrap flex"
        >
          {{ record.first_name }} {{ record.last_name }}
        </span>

        <span
          slot="actions_role"
          slot-scope="text, record"
          class="text-sm font-medium text-right whitespace-nowrap flex"
        >
          <BaseSelect
            class="not-margin"
            :selectOptions="formOptions"
            v-model="record.group_name"
            @input="saveChangeUserRole($event, record.id)"
          />
        </span>
      </a-table>
    </div>
    <div class="mt-4 flex justify-end">
      <a-pagination
        v-model="page"
        :page-size="pageSize"
        :total="count"
        show-less-items
        size="large"
      />
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "Usuario",
    dataIndex: "name",
    key: "name",
    width: "33%",
    scopedSlots: { customRender: "actions_name" },
  },
  {
    title: "Correo electrónico",
    dataIndex: "email",
    ley: "email",
  },
  {
    title: "Rol",
    dataIndex: "role",
    key: "role",
    width: "33%",
    scopedSlots: { customRender: "actions_role" },
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      loading: false,
      current: null,
      showModal: false,
      page: 1,
      searchTerm: null,
      pageSize: 10,
      count: 0,
      search: null,
      myTerm: null,
      role_id: null,
      rolesUsers: {},
      group_name: "",
      stateRole: true,
    };
  },
  created() {
    if (this.$route.query.search) {
      this.searchTerm = this.$route.query.search;
      this.search = this.searchTerm;
    }
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    const { roleid, state } = this.$route.params;
    this.role_id = +roleid;
    this.stateRole = state == "false" ? false : true;
    if (this.stateRole) {
      this.$router.go(-1);
    }
    this.start();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({ query: { page: this.page } });
      }
    },
    "$route.query.page": function () {
      this.start();
    },
  },
  methods: {
    onSearch(value) {
      this.showToast("info", "Buscando resultados...");
      this.searchTerm = value?.trim();
      this.search = this.searchTerm;
      this.$router.replace({
        query: { page: this.page, search: this.searchTerm },
      });
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.start();
      }
      this.page = 1;
    },
    async start() {
      if (this.loading) return;
      let query = `?page=${this.page}&page_size=${this.pageSize}&groups__id=${this.role_id}`;
      if (this.searchTerm) query += `&search=${this.searchTerm}`;

      this.loading = true;
      let { error, data } = await this.$api.readUser(query);
      this.loading = false;

      if (error) {
        this.data = [];
        this.showToast("error", "Error consultando la lista de usuarios.");
      }

      if (data) {
        this.data = data.results.filter((item) =>
          item.groups.includes(this.role_id)
        );
        this.count = data.count;
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    saveChangeUserRole(idRole, idUser) {
      this.rolesUsers[idUser] = +idRole;
    },
    async inactiveRole() {
      const users = Object.keys(this.rolesUsers).map((item) => +item);
      this.showToast("info", "Procesando cambio de estado...");
      this.loading = true;
      let { error, data } = await this.$api.changeStateRole(
        {
          state: this.stateRole,
          role_users: this.rolesUsers,
          users,
        },
        this.role_id
      ); //Change state
      this.loading = false;

      if (error) {
        this.showToast("error", "Error cambiando estado del usuario.");
      }

      if (data) {
        this.showToast("success", "Cambio de estado éxitoso.");
        this.$store.dispatch("form/loadListOptions", {
          endpoint: "group/",
          name: "optionsGroupCompany",
        });
        this.$router.go(-1);
      }
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form?.optionsGroupCompany?.filter(
        (item) => item?.detail_group?.state
      );
    },
  },
};
</script>

<style scoped>
.not-margin {
  margin-top: 0px;
}
</style>