<template>
  <div>
      <UserRoleTable />
  </div>
</template>

<script>
import UserRoleTable from '@/components/Dashboard/Roles/UserRoleTable'
export default {
  components: {
    UserRoleTable
  }
}
</script>